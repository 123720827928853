import { ServiceProviderSignUp } from '@/common/model/serviceprovider/service-provider-model';
import ServiceProviderServices from '@/common/services/service-provider-service';
import { Vue } from 'vue-property-decorator';
export default class invitelink extends Vue.extend({
    name: "invitelink",
    /************************************/
    /* Data Object Section *
    /************************************/
    data() {
        return {
            signupCode: "",
            isEmailUnique: true
        };
    },
    watch: {
        '$route.query.email': {
            handler(value) {
                if (value) {
                    value = value.replace(" ", "+");
                    this.getInvitationCode(value);
                }
            },
            immediate: true
        }
    },
    /************************************/
    /* Method Section *
    /************************************/
    methods: {
        async getInvitationCode(email = "") {
            setTimeout(async () => {
                let _technicianEmail = new ServiceProviderSignUp.ServiceProviderEmail(email);
                await ServiceProviderServices.checkEmailIsUnique(_technicianEmail)
                    .then(async (response) => {
                    if (response === true) {
                        await ServiceProviderServices.getSignupCode(email)
                            .then((response) => {
                            this.signupCode = response;
                        }).catch((r) => {
                            this.signupCode = "";
                        });
                    }
                    else {
                        this.isEmailUnique = response;
                    }
                }).catch((r) => {
                    this.isEmailUnique = false;
                });
            }, 50);
        },
    },
}) {
}
