import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
Vue.use(Vuetify);
export default new Vuetify({
    icons: {
        iconfont: 'fa',
        values: {
            cancel: 'fas fa-ban',
            clear: 'fal fa-times',
            delete: 'fal fa-times',
            checkboxOff: 'fal fa-circle',
            checkboxOn: 'fas fa-check-circle',
            expand: 'fal fa-angle-down',
            dropdown: 'fal fa-angle-down',
            radioOn: 'fal fa-dot-circle',
            radioOff: 'fal fa-circle'
        }
    },
    theme: {
        dark: true,
        options: {
            customProperties: true
        },
        themes: {
            light: {
                base: '#ffffff',
                primary: '#f5f5f5',
                primaryDark: '#e5e5e5',
                secondaryLight: '#939dac',
                secondary: '#40444a',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warningLight: '#ffc700',
                warning: '#c59503',
                font: '#333333',
                fontReverse: '#ffffff',
                fontLight: '#a7a7a7',
                tertiary: '#07a3ab',
                overlay: '#212121',
                anchorBlack: '#333333',
                disable: '#c5c5c5' //#8e8e8e
            },
            dark: {
                base: '#26282b',
                primary: '#2f3238',
                primaryDark: '#40444a',
                secondaryLight: '#939dac',
                secondary: '#8e8c8c',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warningLight: '#ffc700',
                warning: '#c59503',
                font: '#ffffff',
                fontReverse: '#333333',
                fontLight: '#a7a7a7',
                tertiary: '#8BF0DE',
                overlay: '#848484',
                anchorBlack: '#c3d3af',
                disable: '#777777'
            }
        }
    }
});
