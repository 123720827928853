const serviceProviderSignupUrl = window.serviceProviderSignup;
const getTimeZoneAzureFunctionCode = window.getTimeZoneAzureFunctionCode;
const verifyTechnicianEmailAzureFunction = window.verifyTechnicianEmailAzureFunction;
const createServiceProviderFunction = window.createServiceProviderFunction;
const getSignupCodeFunction = window.getSignupCodeFunction;
const getEmailFromSignupCodeFunction = window.getEmailFromSignupCodeFunction;
// timeout constant
export const dropdownSearchTimeout = 300;
export const apiConstants = {
    apiController: {
        serviceprovider: "serviceprovider",
    },
    apiAction: {
        serviceProviderUrls: {
            gettimezoneazurefunction: "gettimezoneazurefunction",
            getsignupcodefunction: "GetSignupCode",
            getemailfromsignupcodefunction: "GetEmailFromSignupCode"
        }
    }
};
export const constants = {
    apiUrl: {
        masterModuleUrl: {
            defaultmasterDialog: "MasterModule/ShowDialog",
            closeDialog: "MasterModule/AbortLeave",
            loaderUrl: "loaderModule/ShowLoader",
        },
        serviceProviderUrls: {
            createServiceProvider: serviceProviderSignupUrl + "/api/CreateServiceProviderFunction" + "?code=" + createServiceProviderFunction,
            verifyTechnicianEmailAzureFunction: serviceProviderSignupUrl + "/api/verifytechnicianemailazurefunction" + "?code=" + verifyTechnicianEmailAzureFunction,
            getTimeZoneAzureFunction: serviceProviderSignupUrl + "/api/" + apiConstants.apiAction.serviceProviderUrls.gettimezoneazurefunction + "?code=" + getTimeZoneAzureFunctionCode,
            getSignupCodeFunction: serviceProviderSignupUrl + "/api/" + apiConstants.apiAction.serviceProviderUrls.getsignupcodefunction + "?code=" + getSignupCodeFunction,
            getEmailFromSignupCodeFunction: serviceProviderSignupUrl + "/api/" +
                apiConstants.apiAction.serviceProviderUrls.getemailfromsignupcodefunction + "?code=" + getEmailFromSignupCodeFunction,
        },
    },
    localStorage: {
        keys: {
            rules: "rules",
            routes: "routes",
        }
    },
    validations: {
        phoneNumberMinLength: 6,
        phoneNumberMaxLength: 15,
        emailMaxLength: 200
    },
    AppVersion: "api-version=1.0",
    refreshToken: window.GoreloWebDomain + "/getNewTokenWithRefreshToken",
    validateIfUserLoggedIn: window.GoreloWebDomain + "/validateIfUserLoggedIn"
};
