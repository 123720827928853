export var ServiceProviderSignUp;
(function (ServiceProviderSignUp) {
    class ServiceProvider {
        constructor(__serviceProviderId, __serviceProviderName, __timeZone, __firstName, __middleName, __lastName, __phone, __emailAddress, __stripeToken, __last4CardDigits, __stripePlanId, __isLoginWithAzureAd, __serviceProviderType, __promotionCode) {
            this.recaptchaToken = "";
            this.serviceProviderId = __serviceProviderId;
            this.serviceProviderName = __serviceProviderName;
            this.timeZone = __timeZone;
            this.firstName = __firstName;
            this.middleName = __middleName;
            this.lastName = __lastName;
            this.phone = __phone;
            this.stripeToken = __stripeToken;
            this.last4CardDigits = __last4CardDigits;
            this.email = __emailAddress;
            this.stripePlanId = __stripePlanId;
            this.isLoginWithAzureAd = __isLoginWithAzureAd;
            this.serviceProviderType = __serviceProviderType;
            this.promotionCode = __promotionCode;
        }
    }
    ServiceProviderSignUp.ServiceProvider = ServiceProvider;
    class ServiceProviderEmail {
        constructor(_email) {
            this.email = _email;
        }
    }
    ServiceProviderSignUp.ServiceProviderEmail = ServiceProviderEmail;
    class ServiceProviderProfile {
        constructor(__serviceProviderId, __serviceProviderName, __emailAddress, __phone, __phoneExt, __mailingAddress, __billingAddress, __technicianId) {
            this.serviceProviderId = __serviceProviderId;
            this.serviceProviderName = __serviceProviderName;
            this.emailAddress = __emailAddress;
            this.phone = __phone;
            this.phoneExt = __phoneExt;
            this.mailingAddress = __mailingAddress;
            this.billingAddress = __billingAddress;
            this.technicianId = __technicianId;
        }
    }
    ServiceProviderSignUp.ServiceProviderProfile = ServiceProviderProfile;
    class ServiceProviderLocationProfile {
        constructor(__serviceProviderLocationId, __name, __timeZone, __isDefault) {
            this.serviceProviderLocationId = __serviceProviderLocationId;
            this.name = __name;
            this.timeZone = __timeZone;
            this.isDefault = __isDefault;
        }
    }
    ServiceProviderSignUp.ServiceProviderLocationProfile = ServiceProviderLocationProfile;
    class ServiceProviderEmailConfiguration {
        constructor(__emailName, __emailConfig) {
            this.emailName = __emailName;
            this.emailConfig = __emailConfig;
        }
    }
    ServiceProviderSignUp.ServiceProviderEmailConfiguration = ServiceProviderEmailConfiguration;
    class ServiceProviderCreditCard {
        constructor(__isPay, __customerId, __last4Digits) {
            this.isPay = __isPay;
            this.stripeCustomerId = __customerId;
            this.last4Digits = __last4Digits;
        }
    }
    ServiceProviderSignUp.ServiceProviderCreditCard = ServiceProviderCreditCard;
    class ServiceProviderDefaultLocation {
        constructor(__serviceProviderLocationId, __isDefault) {
            this.serviceProviderLocationId = __serviceProviderLocationId;
            this.isDefault = __isDefault;
        }
    }
    ServiceProviderSignUp.ServiceProviderDefaultLocation = ServiceProviderDefaultLocation;
})(ServiceProviderSignUp || (ServiceProviderSignUp = {}));
