import Vue from "vue";
export default class Master extends Vue
    .extend({
    name: 'Master',
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {}
}) {
}
