import { emailStandard } from "@/common/helper/custom-validator";
import { CustomDropDown } from "@/common/model/custom-dropdown";
import { ServiceProviderSignUp } from '@/common/model/serviceprovider/service-provider-model';
import ServiceProviderServices from '@/common/services/service-provider-service';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Vue } from 'vue-property-decorator';
import vueStringElement from 'vue-stripe-elements-plus';
import { email, required } from 'vuelidate/lib/validators';
import VueRecaptcha from 'vue-recaptcha';
import { Enums } from "@/common/helper/enum";
let IDropDown;
export default class signup extends Vue.extend({
    name: "singup",
    /************************************/
    /* Component Section *
    /************************************/
    components: {
        Card: vueStringElement.Card,
        CardNumber: vueStringElement.CardNumber,
        CardExpiry: vueStringElement.CardExpiry,
        CardCvc: vueStringElement.CardCvc,
        VueRecaptcha
    },
    /************************************/
    /* Property Section *
    /************************************/
    props: ['stripe', 'options'],
    /************************************/
    /* Watch Section *
    /************************************/
    watch: {
        'manageServiceProvider.userEmail': {
            handler() {
                this.checkEmailIsUnique();
            }
        },
        "$route.query.inviteid": {
            handler(value) {
                if (value) {
                    this.getInvitationEmail(value);
                }
            },
            immediate: true
        }
    },
    /************************************/
    /* Data Object Section *
    /************************************/
    data() {
        return {
            IsLoaded: false,
            complete: false,
            showSaveDialog: false,
            IsLoginWithAzureAd: false,
            stripeOptions: {
                style: {
                    base: {
                        color: '#ffffff',
                        fontFamily: 'arial',
                        fontSmoothing: 'antialiased',
                        fontSize: '16px',
                        '::placeholder': {
                            color: '#dcdcdc'
                        }
                    },
                    empty: {
                        color: '#ffffff',
                    }
                }
            },
            number: false,
            expiry: false,
            cvc: false,
            serviceProviderTimeZoneDropdownInstance: null,
            serviceProviderTimeZoneObject: null,
            serviceProviderTimeZoneKey: 0,
            isSubmit: false,
            showSaveServiceProviderModalError: false,
            HasEmailIsUnique: true,
            isLoading: false,
            timeZone: [],
            stripePlanId: 0,
            manageServiceProvider: {
                serviceProvideName: '',
                userFirstName: '',
                userMiddleName: '',
                userLastName: '',
                userEmail: '',
                userCellPhone: '',
                selectedTimeZone: '',
                disableEmail: true,
                promotionCode: ''
            },
            serviceProviderTypeObject: null,
            serviceProviderTypeObjectKey: 0,
        };
    },
    /************************************/
    /* Validation Section *
    /************************************/
    validations: {
        serviceProviderTimeZoneObject: {
            selectedValue: {
                required
            }
        },
        manageServiceProvider: {
            serviceProvideName: { required },
            userFirstName: { required },
            userLastName: { required },
            userEmail: { required, email, emailStandard },
            selectedTimeZone: { required }
        },
        validationGroup: ['manageServiceProvider', 'serviceProviderTimeZoneObject']
    },
    /************************************/
    /* Method Section *
    /************************************/
    methods: {
        async getInvitationEmail(inviteid) {
            setTimeout(async () => {
                await ServiceProviderServices.getSignupEmail(inviteid)
                    .then((response) => {
                    // if there is any email address then set it, else send the user to login screen
                    if (response) {
                        this.manageServiceProvider.userEmail = response;
                    }
                    else {
                        window.location.href = "window.goreloWebBaseURL";
                    }
                }).catch((r) => {
                });
            }, 50);
        },
        async signupFormSubmit(recaptchaToken = "") {
            setTimeout(async () => {
                this.isSubmit = true;
                this.$v.validationGroup.manageServiceProvider.$touch();
                if (this.$v.validationGroup.manageServiceProvider.$invalid || this.manageServiceProvider.selectedTimeZone == '') {
                    this.showSaveServiceProviderModalError = true;
                    this.isSubmit = false;
                    return;
                }
                this.isLoading = true;
                var _technicianEmail = new ServiceProviderSignUp.ServiceProviderEmail(this.manageServiceProvider.userEmail);
                await ServiceProviderServices.checkEmailIsUnique(_technicianEmail)
                    .then((response) => {
                    this.isLoading = false;
                    this.HasEmailIsUnique = response;
                }).catch((r) => {
                    this.HasEmailIsUnique = false;
                    this.resetCaptchaToken();
                });
                if (!this.HasEmailIsUnique) {
                    this.isSubmit = false;
                    this.resetCaptchaToken();
                    return;
                }
                let serviceproviderType = Enums.ServiceProviderType.MSP;
                if (this.serviceProviderTypeObject.selectedValue && this.serviceProviderTypeObject.selectedValue[this.serviceProviderTypeObject.valueField] == 2) {
                    serviceproviderType = Enums.ServiceProviderType.Enterprise;
                }
                var model = new ServiceProviderSignUp.ServiceProvider(0, this.manageServiceProvider.serviceProvideName, this.manageServiceProvider.selectedTimeZone, this.manageServiceProvider.userFirstName, this.manageServiceProvider.userMiddleName, this.manageServiceProvider.userLastName, this.manageServiceProvider.userCellPhone, this.manageServiceProvider.userEmail, "", "", this.stripePlanId, this.IsLoginWithAzureAd, serviceproviderType, this.manageServiceProvider.promotionCode);
                //model.recaptchaToken = recaptchaToken;
                ServiceProviderServices.saveServiceProvider(model)
                    .then(() => {
                    this.clearFormData();
                    this.$v.validationGroup.manageServiceProvider.$reset();
                    this.$v.validationGroup.serviceProviderTimeZoneObject.$reset();
                    this.$router.push("/signup-feedback");
                    this.isSubmit = false;
                    //this.resetCaptchaToken();
                }).catch((error) => {
                    console.log(error);
                    this.isSubmit = false;
                    //this.resetCaptchaToken();
                });
            }, 50);
        },
        clearFormData() {
            this.manageServiceProvider.serviceProvideName = "";
            this.manageServiceProvider.userFirstName = "";
            this.manageServiceProvider.userMiddleName = "";
            this.manageServiceProvider.userLastName = "";
            this.manageServiceProvider.userCellPhone = "";
            this.manageServiceProvider.userEmail = "";
            this.manageServiceProvider.selectedTimeZone = "";
        },
        statusForTimeZone(validation) {
            if (validation.$error)
                return "error-row";
            return "";
        },
        status(validation) {
            return {
                error: validation.$error,
                dirty: validation.$dirty,
            };
        },
        statusForEmailAddress(validation) {
            return {
                error: validation.$error || !this.HasEmailIsUnique,
                dirty: validation.$dirty,
            };
        },
        getTimeZoneObject(selectedValue) {
            var timeZone = new CustomDropDown.DropDown(this.timeZone, selectedValue, selectedValue, "lang.common.labels.timeZone", "serviceProviderTimeZoneObject", this.onTimeZoneSelectedValueChanged, true, (instance) => {
                this.IsLoaded = true;
                this.serviceProviderTimeZoneDropdownInstance = instance;
            }, "value", "getNameWithZone", false, true);
            timeZone.onBlurCallback = () => {
                this.$v.serviceProviderTimeZoneObject.selectedValue.$touch();
            };
            return timeZone;
        },
        onClickExecuteReCaptcha() {
            this.$refs.recaptcha.execute();
        },
        onCaptchaVerified(recaptchaToken) {
            console.log(recaptchaToken);
            this.signupFormSubmit(recaptchaToken);
        },
        onCaptchaExpired() {
            console.log("token expired");
            this.resetCaptchaToken();
        },
        resetCaptchaToken() {
            this.$refs.recaptcha.reset();
        },
        onTimeZoneSelectedValueChanged(value, objectName, listName) {
            if (typeof value != 'string') {
                var object = this[objectName];
                object.selectedValue = value;
            }
        },
        checkEmailIsUnique() {
            this.HasEmailIsUnique = true;
            //if (!this.manageServiceProvider.userEmail) {
            //    this.HasEmailIsUnique = true;
            //    return;
            //}
            if (!this.ValidateEmail(this.manageServiceProvider.userEmail))
                return;
        },
        ValidateEmail(email) {
            var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (mailformat.test(String(email).toLowerCase())) {
                return true;
            }
            else {
                return false;
            }
        },
        selectedDropdownValue(timezone) {
            if (timezone && typeof timezone != 'string') {
                this.manageServiceProvider.selectedTimeZone = timezone.value;
            }
            else {
                this.manageServiceProvider.selectedTimeZone = '';
            }
        },
        redirectToSignIn() {
            localStorage.setItem('IdToken', "");
            var url = window.location.href;
            var arr = url.split("/");
            window.location.href = window.goreloWebBaseURL + "/logout?redirecturl=";
        },
        getServiceProviderTypeObject(selectedValue) {
            var object = new CustomDropDown.DropDown([{ 'id': 1, 'value': 'MSP' }, { 'id': 2, 'value': 'Enterprise' }], { 'id': 1, 'value': 'MSP' }, { 'id': 1, 'value': 'MSP ' }, "lang.common.labels.serviceProviderType", 'serviceProviderTypeObject', this.onSelectedValueChanged, true, (instance) => {
            }, 'id', 'value', true);
            object.isClearable = false;
            return object;
        },
        onSelectedValueChanged(value, objectName) {
            var object = this[objectName];
            object.selectedValue = value;
            object.defaultSelectedValue = value;
        },
    },
    /************************************/
    /* Created Section *
    /************************************/
    created() {
        ServiceProviderServices.getServieproviderTimeZoneList()
            .then((response) => {
            for (let i = 0; i < response.length; i++) {
                let zone = DateTime.utc().setZone(response[i].value).toFormat("ZZ");
                response[i].nameWithZone = "(" + zone + ") " + response[i].text;
                response[i].isNegative = zone.includes("-");
            }
            let negativeValuedZones = _.filter(response, function (timeZone) {
                return timeZone.isNegative == true;
            });
            let postiveValuedZones = _.filter(response, function (timeZone) {
                return timeZone.isNegative == false;
            });
            //sort values with minus in desc order and values with plus in asc order
            negativeValuedZones = _.orderBy(negativeValuedZones, (timeZone) => timeZone.nameWithZone, "desc");
            postiveValuedZones = _.orderBy(postiveValuedZones, (timeZone) => timeZone.nameWithZone, "asc");
            this.timeZone = negativeValuedZones.concat(postiveValuedZones);
        });
        if (this.$route.query.payment) {
            this.stripePlanId = Number(this.$route.query.payment);
        }
        else {
            this.stripePlanId = 1;
        }
        this.serviceProviderTypeObject = this.getServiceProviderTypeObject({ 'id': 1, 'value': 'MSP ' });
    }
}) {
}
