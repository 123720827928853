import { Vue } from 'vue-property-decorator';
export default class SignupFeedback extends Vue.extend({
    name: "SignupFeedback",
    /************************************/
    /* Component Section *
    /************************************/
    /************************************/
    /* Computed Section *
    /************************************/
    computed: {},
    /************************************/
    /* Watch Section *
    /************************************/
    /************************************/
    /* Data Object Section *
    /************************************/
    data() {
        return {};
    },
    /************************************/
    /* Validation Section *
    /************************************/
    validations: {},
    /************************************/
    /* Method Section *
    /************************************/
    methods: {},
    /************************************/
    /* Created Section *
    /************************************/
    created() {
    },
    /************************************/
    /* Mounted Section *
    /************************************/
    mounted() {
    },
    /************************************/
    /* Destroyed Section *
    /************************************/
    destroyed() {
    }
}) {
}
