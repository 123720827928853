import Vue from 'vue';
import VueRouter from 'vue-router';
import vuetify from '@/common/components/plugins/vuetify-plugin';
import Vuelidate from 'vuelidate';
import i18n from '@/common/locales';
import singup from '@/account/views/signup.vue';
import SingupFeedback from '@/account/views/signup-feedback.vue';
import PrivacyPolicy from '@/account/views/privacy-policy.vue';
import invitelink from '@/account/views/invitelink.vue';
i18n.locale = 'en';
Vue.config.performance = true;
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.component('CustomDropdownComponent', () => import('@/common/components/dropdown/dropdown.vue'));
const _routes = [
    { path: '/', name: 'signup', component: singup },
    { path: '/signup-feedback', name: 'signup-feedback', component: SingupFeedback },
    { path: '/privacy-policy', name: 'privacy-policy', component: PrivacyPolicy },
    { path: '/invitelink', name: 'invitelink', component: invitelink },
];
let router = new VueRouter({
    mode: 'history',
    routes: _routes
});
var AppElement = document.getElementById("app");
if (AppElement == null) {
    document.getElementById("appContainer").innerHTML = "<div id='app'></div>";
}
new Vue({
    router: router,
    vuetify,
    i18n,
    el: '#app',
    render: h => h(require('./master.vue').default)
});
var UseAzureAdB2C = (window.UseAzureAdB2C);
if (UseAzureAdB2C == "False") {
    Vue.config.devtools = true;
    if (module.hot) {
        module.hot.accept();
    }
}
