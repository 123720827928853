export var CustomDropDown;
(function (CustomDropDown) {
    class DropDown {
        constructor(source, selectedValue, defaultSelectedValue, label, objectName, onSelectedValueChangedCallback, setInstanceToParent = false, setInstanceToParentCallback = null, valueField = 'id', textField = 'name', isSelect = false, isAutoComplete = false, sourceUrl = null, isMultiple = false, useChips = false, useTags = false, isClearable = true, onBlurCallback = null, listVariableName = '', isDisabled = false, isPropWatcherEnable = false) {
            this.setInstanceToParent = false;
            // optional fields
            this.valueField = 'id';
            this.textField = 'name';
            this.isSelect = false;
            this.isAutoComplete = false;
            this.sourceUrl = null;
            this.isMultiple = false;
            this.useChips = false;
            this.useTags = false;
            this.isPropWatcherEnable = false;
            this.isClearable = true;
            this.listVariableName = '';
            this.showLoading = false;
            this.closeOnClickIfEmpty = false;
            this.isRequired = false;
            this.isPropWatcherEnable = isPropWatcherEnable;
            this.source = source;
            this.selectedValue = selectedValue;
            this.defaultSelectedValue = defaultSelectedValue;
            this.label = label;
            this.objectName = objectName;
            this.valueField = valueField;
            this.textField = textField;
            this.isSelect = isSelect;
            this.isAutoComplete = isAutoComplete;
            this.sourceUrl = sourceUrl;
            this.isMultiple = isMultiple;
            this.useChips = useChips;
            this.useTags = useTags;
            this.isClearable = isSelect == false && isClearable;
            this.listVariableName = listVariableName;
            this.setInstanceToParent = setInstanceToParent;
            this.setInstanceToParentCallback = setInstanceToParentCallback;
            this.onSelectedValueChangedCallback = onSelectedValueChangedCallback;
            this.onBlurCallback = onBlurCallback;
            this.IsStateApplied = () => {
                if (typeof this.StateGetter == "undefined") {
                    return false;
                }
                else if (typeof this.StateGetter != "undefined") {
                    return true;
                }
            };
        }
    }
    CustomDropDown.DropDown = DropDown;
})(CustomDropDown || (CustomDropDown = {}));
