import { UrlHelper } from '@/common/helper/url-helper-service';
import { HttpHelper } from '@/common/helper/http-helper-service';
import { constants } from '@/common/constant/constants';
export default class ServiceProviderServices {
    constructor() {
    }
    static async checkEmailIsUnique(dataModel) {
        return HttpHelper.postData(constants.apiUrl.serviceProviderUrls.verifyTechnicianEmailAzureFunction, dataModel);
    }
}
ServiceProviderServices.saveServiceProvider = (params) => {
    let url = constants.apiUrl.serviceProviderUrls.createServiceProvider;
    url = UrlHelper.getUrl(url);
    return HttpHelper.postData(url, params);
};
ServiceProviderServices.getServieproviderTimeZoneList = () => {
    return HttpHelper.getData(constants.apiUrl.serviceProviderUrls.getTimeZoneAzureFunction);
};
ServiceProviderServices.getSignupCode = (email) => {
    return HttpHelper.getData(constants.apiUrl.serviceProviderUrls.getSignupCodeFunction + "&email=" + email);
};
ServiceProviderServices.getSignupEmail = (inviteid) => {
    return HttpHelper.getData(constants.apiUrl.serviceProviderUrls.getEmailFromSignupCodeFunction + "&inviteid=" + inviteid);
};
